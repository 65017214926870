.data-search-main {
  height: 100vh;
  width: 60%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  background: #1c1d21;
  border: 1px solid #4b5056;
  box-shadow: -4px 0px 55px rgba(255, 255, 255, 0.1);
  color: #ffffff;

  .modal-header {
    height: 55px;
    padding: 20px 16px;
    display: inline-flex;
    width: 100%;
    font-size: 16px;
    justify-content: space-between;
    line-height: 21px;

    .close-btn {
      img {
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
  }

  .divider {
    border-bottom: 1px solid #4b5056;
    margin: 0 16px;
  }

  .modal-footer {
    display: inline-flex;
    position: absolute;
    right: 50px;
    bottom: 40px;
    align-items: center;

    button {
      border-radius: 4px;
      text-transform: capitalize;
      font-weight: 100;
      margin-left: 10px;

      &.disabled {
        background: #404040;
      }

      &.active {
        background: #247cff;
      }
    }
  }

  .content {
    .grid-layout {
      height: calc(100vh - 180px);

      .grid-item {
        border-right: 1px solid #4b5056;
        overflow: hidden !important;

        &:last-child {
          border-right: none;

          .item-name {
            &:hover {
              span {
                &:after {
                  content: '';
                }
              }
            }
            &.selected {
              span {
                &:after {
                  content: '';
                }
              }
            }
          }
        }

        .item-name {
          cursor: default;
          text-transform: capitalize;
          display: 'flex';
          align-items: flex-end;
          justify-content: flex-end;

          &:hover {
            background-color: rgba(36, 124, 255, 0.2);
            overflow-y: auto !important;
            color: #ffffff;
          }

          &.selected {
            background-color: rgba(36, 124, 255, 0.2);
            font-weight: 600;
            color: #ffffff;
            border-width: 1px 0;
            border-style: solid;
            border-color: #247cff;

            span {
              &:after {
                position: absolute;
                content: url('../../../images/next-icon.svg');
                margin-left: 7px;
                padding: 2px;
              }
            }
          }

          span {
            padding: 4px 0;
            position: relative;
          }
        }

        .list-item {
          text-transform: capitalize;
          overflow-y: auto;
          max-height: calc(100vh - 250px);
        }

        .search-box {
          width: 100%;
          padding: 4px 8px 12px 8px;

          input {
            background: transparent;
            border: none;
            border-bottom: 1px solid #ffffff;
            outline: none;
            caret-color: #ffffff;
            line-height: 26px;
            background: url('../../../images/search-icon.svg') no-repeat scroll
              0 6px;
            padding-left: 20px;
            color: #ffffff;
            width: 100%;
          }
        }
      }
    }
  }
}
