$border-radius: 0.25rem;
$dark-theme: true;
$primary: #e4e7eb;
$contrast: #3392ff;
$secondary: #1c1d21;
$info: #717c8e;
$success: #77c188;
$warning: #35292c;
$error: #bc6969;
$body-text: #717c8e;
$body-bg: #000;
$bs-body-bg: $body-bg;
$bs-body-color: $body-text;
$headings-text: #e4e7eb;
$subtle-text: #717c8e;
$disabled-text: #3e3d3d;
$component-text: #e4e7eb;
$component-bg: #1c1d21;
$base-text: #e4e7eb;
$base-bg: #1c1d21;
$series-a: $contrast;
$series-b: #9facbb;
$series-c: #ffcd00;
$series-d: #00ffa2;
$series-e: #e16100;
$series-f: #ffa5c0;
$series-g: #1f51ff;
$hovered-text: #f4f2ed;
$hovered-bg: $series-a;
$selected-text: $primary;
$selected-bg: $series-g;
$button-text: #717c8e;
$button-bg: #1c1d21;
$link-text: $primary;
$link-hover-text: #7b5f27;

@import '~@progress/kendo-theme-bootstrap/dist/all.scss';

.k-dropdown {
  cursor: pointer;
}

span.k-dropdown-wrap span.k-input strong {
  color: $primary;
}

.k-dropdown .k-dropdown-wrap .k-icon {
  color: $primary;
}

.k-state-empty .k-label {
  color: transparent;
}

span.number strong {
  color: $primary;
}

.k-label {
  .k-textbox,
  .k-widget {
    margin-left: 2rem;
  }
}

.k-textbox.small {
  width: 4em;
}
.k-textbox.medium {
  width: 8em;
}

.k-widget.k-dropdown.small {
  width: 4em;
}
.k-widget.k-dropdown.medium {
  width: 8em;
}
.k-widget.k-dropdown.normal {
  width: 12.4em;
}
.k-widget.k-dropdown.large {
  width: 20em;
}

.k-button-group .k-button {
  padding: 1em 2em;
}

.k-button.k-state-selected {
  color: $primary;
}

.k-selected{
  background-color:#247CFF !important;
}

.k-popup {
  border-color: #4B5056;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.k-list {
  background-color: #313438 !important;
}

.k-list-item:hover {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.1);
}
