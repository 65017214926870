@import './variables';
@import './blockscholes_theme/blockscholes_bootstrap';
@import './bootstrap';
@import './typography';
// @import './kendo';

html {
  font-size: 14px;
}

body {
  font-family: $font-family;
  background-color: $bs-body-bg;
  color: $bs-body-color;
}

main {
  // background-color: rgba(236, 238, 239, 0.5);
  padding: 3rem;
}

.add-new .k-select {
  display: none;
}

a:hover {
  text-decoration: none;
}

@import '~@progress/kendo-theme-default/dist/all.scss';

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.scroll-fix {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: overlay;
}

.k-menu-group .k-item {
  font-family: 'OpenSans';
}
.k-menu-group .k-item > .k-link:hover {
  background-color: $contrast;
  filter: brightness(140%);
}
.k-menu-group .k-item > .k-link:active {
  background-color: $contrast;
  filter: brightness(150%);
}

.k-loader-primary {
  color: $contrast !important;
}

.offset-y {
  transform: translateY(-200px);
}

// TODO: move these out of main.scss
.k-input-value-text {
  text-transform: capitalize;
}
.k-time-separator {
  display: none !important;
}

.nav-live-feed {
  margin: auto !important;
  @media (max-width: 1130px) {
    display: none;
  }

  .k-link {
    height: 0 !important;
    padding: 0 !important;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  overflow: hidden;
  position: relative;
}

.spinner::before,
.spinner::after {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 15px solid transparent;
  mix-blend-mode: overlay;
  animation: spinner var(--duration) var(--timing) infinite;
  pointer-events: none;
}

.spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-top-color: white;
  border-right-color: white;
  animation: spinner 1.2s ease-in-out infinite;
  // --duration: 2s;
  // --timing: ease-in-out;
}

.spinner::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid transparent;
  border-bottom-color: white;
  animation: spinner 1.5s ease-out infinite;
}
